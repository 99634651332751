
import { defineComponent, reactive } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import CModal from "@/components/Modal.vue";

const VSpecialistUnlink = defineComponent({
  name: "VSpecialistUnlink",
  components: { CModal },
  setup() {
    useHead({ title: "Desvincular especialista do usuário | obmed" });

    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const _id = Number(route.params._id);
    const loading = reactive({ confirm: false });

    function handleClose() {
      router.back();
    }

    async function deleteSpecialist() {
      loading.confirm = true;
      const response = await store.dispatch("unlinkSpecialist", { form: { cd_especialista: _id } });
      loading.confirm = false;

      if (response?.status === 200) {
        store.dispatch("getSpecialists");
        handleClose();
      }
    }

    return { loading, deleteSpecialist, handleClose };
  },
});

export default VSpecialistUnlink;
